import { useStoreState } from "pullstate";
import MenuStore, { setHomePage, setMenus } from "../store/MenuStore";
import { getHomePage, getMenus } from "../store/Selector";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axiosHelper from "../helpers/axiosHelper";
import dlcLogo from "../assets/LOGO_FINALE1.png";
import dlcLogoWhite from "../assets/LOGO_WHITE.png";
import styles from "./HomePage.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faAngleDown, faXmark } from "@fortawesome/free-solid-svg-icons";
import PopUpLanguages from "./PopUp/PopUpLanguages/PopUpLanguages";
import italy from "../assets/Flag_of_Italy.svg.png";
import england from "../assets/istockphoto-486407806-612x612.jpg";
import france from "../assets/istockphoto-657672390-612x612.jpg";
import germany from "../assets/germany-flag-flag-germany-vector-illustration_685751-83.jpg.avif";
import russia from "../assets/istockphoto-691333844-612x612.jpg";
import portogallo from "../assets/istockphoto-1220855804-612x612.jpg";
import belgio from "../assets/istockphoto-483320865-612x612.jpg";

const languagesList: Language[] = [
  { idLanguage: 1, image: italy, name: "Italiano" },
  { idLanguage: 2, image: england, name: "English" },
  { idLanguage: 3, image: france, name: "Francaise" },
  { idLanguage: 4, image: germany, name: "German" },
  { idLanguage: 5, image: russia, name: "Russian" },
  { idLanguage: 6, image: portogallo, name: "Portuguese" },
  { idLanguage: 7, image: belgio, name: "Belgian" },
];

const HomePage: React.FC = () => {
  const redirect = useNavigate();
  const [languages, setLanguages] = useState<Language[]>([]);
  const [languageId, setLanguageId] = useState(1);
  const [showLanguages, setShowLanguages] = useState(false);
  const homePage = useStoreState(MenuStore, getHomePage);
  const allMenu = useStoreState(MenuStore, getMenus);
  const [home, setHome] = useState<HomePage>();
  const params = useParams();

  const getHomePageFromId = async (idClient: number) => {
    await axiosHelper.getHomePageByIdClient(idClient).then((response) => {
      console.log(response);
      setHomePage(response);
    });
  };

  useEffect(() => {
    if (homePage) {
      setHome(homePage);
      setLanguages(homePage.languages);
      homePage.languages.map((item, index) => {
        index = 0 && setLanguageId(item.idLanguage);
        getClientMenus();
      });
    }
  }, [homePage]);

  const getClientMenus = async () => {
    await axiosHelper
      .getMenusPageByIdClient(homePage.client_id)
      .then((response) => {
        console.log(response);
        setMenus(response);
      })
      .catch((error) => {
        throw error;
      });
  };

  useEffect(() => {
    if (params.id) {
      getHomePageFromId(parseInt(params.id));
    }
  }, []);

  const goToMenuSelector = () => {
    if (allMenu) {
      redirect(`/menuSelector/${params.id}`);
    }
  };

  return (
    <>
      <div className=" col-xl-6  col-12  h-100 d-flex flex-column align-items-center justify-content-center ">
        <div
          className="d-flex flex-column align-items-center justify-content-start col-12  overflow-hidden"
          style={{
            height: "100vh",
            maxWidth: "100vh",
            // borderRadius: "8px",

            backgroundColor: `${home?.background_color}`,
          }}
        >
          <div
            className="col-10 mt-4 d-flex flex-row align-items-center justify-content-center "
            style={{ height: "20vh" }}
          >
            <img
              src={home && home.logo_path ? home.logo_path : dlcLogo}
              style={{
                maxHeight: "80px",
                maxWidth: "400px",
              }}
            />
          </div>

          <div
            className={`d-flex flex-column  col-12 mt-4  align-items-center justify-content-center ${
              home && home.image_path ? "d-block" : "d-none"
            } ${styles.backgroundWrapper} `}
            style={{
              height: "80vh",
              zIndex: 1,
              position: "relative",
            }}
          >
            <img
              src={home && home.image_path && home.image_path}
              className={`h-100 ${styles.background}`}
              style={{
                height: "100%",
                width: "100%",

                objectFit: "fill",
              }}
            ></img>
          </div>

          <div
            className="col-10 mt-5 d-flex flex-row align-items-center justify-content-center "
            style={{ zIndex: 2, position: "absolute", top: "35%" }}
          >
            <span
              className="sub-title"
              style={{
                color: `${home?.text_color}`,
                textShadow: "2px 2px 4px black",
              }}
            >
              {home && home.title}
            </span>
          </div>
          <div
            className="col-10 mt-3 d-flex flex-row align-items-center justify-content-center "
            style={{
              zIndex: 2,
              position: "absolute",
              top: "45%",
              textShadow: "2px 2px 4px black",
            }}
          >
            <span
              className="sub-title"
              style={{ color: `${home && home.text_color}` }}
            >
              {home && home.subtitle}
            </span>
          </div>

          <div className="col-10 mt-5 d-flex flex-row align-items-center justify-content-center ">
            <span
              className="text-16 p-3 text-center d-flex flex-row align-items-center justify-content-center flex-wrap "
              style={{
                color: `${home && home.text_color}`,
                zIndex: 2,
                position: "absolute",
                maxWidth: "400px",
                top: "53%",
                textShadow: "2px 2px 4px black",
              }}
            >
              Seleziona la lingua che preferisci per continuare
            </span>
          </div>

          <div
            className="col-10 mt-10 d-flex flex-row align-items-center justify-content-center "
            style={{ position: "absolute", zIndex: 3, top: "60%" }}
          >
            {languageId !== 0 &&
              languagesList.map(
                (lang) =>
                  lang.idLanguage === languageId &&
                  (console.log(lang),
                  (
                    <div className="d-flex flex-row col-12 align-items-center justify-content-center">
                      <div
                        className={` d-flex  flex-row align-items-center justify-content-center btn-${homePage.button_color} me-3`}
                        style={{
                          borderRadius: "10px",
                          maxWidth: "30px",
                        }}
                        onClick={() => setShowLanguages(!showLanguages)}
                      >
                        <FontAwesomeIcon
                          icon={faAngleDown as IconProp}
                          style={{ fontSize: "2rem" }}
                        />
                      </div>
                      <img
                        onClick={() => setShowLanguages(!showLanguages)}
                        src={lang.image}
                        style={{
                          height: "50px",
                          width: "70px",
                          borderRadius: "10px",
                          border: "2px solid black",
                          objectFit: "fill",
                        }}
                      />
                    </div>
                  ))
              )}
          </div>
          <div
            className="d-flex flex-column align-items-center justify-content-end  mt-7 col-12 "
            style={{
              height: "22vh",
              zIndex: 2,
              position: "absolute",
              top: "68%",
            }}
          >
            <div
              className={`mt-10  col-12 d-flex flex-row align-items-center justify-content-center`}
            >
              <div
                className={`btn-${
                  home && home.button_color
                }  d-flex flex-row align-items-center  `}
                onClick={() => goToMenuSelector()}
                style={{
                  borderRadius: "20px",
                  height: "50px",
                  minWidth: "250px",
                  maxWidth: "400px",
                }}
              >
                {home && home.button_color}
              </div>
            </div>
            <div className="d-flex flex-row align-items-center justify-content-center mt-5 col-8 ">
              <span
                className="text me-3"
                style={{
                  color: home && home.text_color,
                  textShadow: "2px 2px 4px black",
                }}
              >
                powered by
              </span>
              <img
                src={home?.image_path ? dlcLogoWhite : dlcLogo}
                style={{
                  height: "10px",
                }}
              />
            </div>
          </div>

          <PopUpLanguages
            popup={showLanguages}
            close={(languageId) => (
              setShowLanguages(false), setLanguageId(languageId)
            )}
            languages={languages}
          />
          {/* {user.subscriptionType === 1 && <div>ciao</div>} */}
          {/* {user.subscriptionType > 1 && <div>{title}</div>} */}
        </div>
      </div>
    </>
  );
};

export default HomePage;
