import { useEffect, useState } from "react";
import styles from "./AllaCarta.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useStoreState } from "pullstate";
import MenuStore from "../store/MenuStore";
import { getHomePage } from "../store/Selector";
import { useNavigate } from "react-router-dom";

interface ConImmaginiProps {
  menu: Menu;
}

const ConImmagini: React.FC<ConImmaginiProps> = (props) => {
  const homePage = useStoreState(MenuStore, getHomePage);
  const [category, setCategoy] = useState(0);
  const [dishes, setDishes] = useState<Dish[]>([]);
  const redirect = useNavigate();

  const fillDishes = () => {};

  useEffect(() => {
    props.menu.categories.map((item, index) => {
      if (index === 0) {
        setCategoy(item.id);
        setDishes(item.dishes);
      }
    });
  }, []);

  return (
    <>
      <div
        className="overflow-hidden"
        style={{ backgroundColor: `${props.menu.head_color}` }}
      >
        <div
          className={`d-flex  flex-column align-items-center justify-content-center col-12 px-4  `}
          style={{ height: "15vh", width: "100%" }}
        >
          <div className=" d-flex flex-row align-items-center justify-content-center col-12 ">
            <div className="col-1 d-flex flex-row align-items-center justify-content-center  ">
              <FontAwesomeIcon
                icon={faAngleLeft as IconProp}
                style={{ color: `${homePage.text_color}`, fontSize: "2.3rem" }}
                onClick={() =>
                  redirect(`/menuSelector/${props.menu.client_id}`)
                }
              />
            </div>
            <div className="col-10 d-flex flex-row align-items-start justify-content-center">
              <span className="  color-light  fw-normal light-title ">
                {props.menu.title}
              </span>
            </div>
            <div className="col-1 "></div>
          </div>
        </div>
        <div className="d-flex flex-column " style={{ minHeight: "75vh" }}>
          <div
            className={`d-flex flex-row  px-4 row flex-nowrap  align-items-center ${
              props.menu.categories.length > 2
                ? "justify-content-between"
                : "justify-content-around"
            } ${styles.scrollableBar} `}
          >
            {props.menu.categories.map((item) => (
              <div
                className={`btn-${props.menu.buttons_color}  rounded  mx-3`}
                onClick={() => (setCategoy(item.id), setDishes(item.dishes))}
              >
                {item.title}
              </div>
            ))}
          </div>
          <div
            style={{
              borderRadius: "25px",
              // minHeight: "65vh",
              backgroundColor: `${props.menu.background_color}`,
            }}
          >
            {dishes.map((item) => (
              <div
                className="d-flex  flex-column align-items-start justify-content-start px-4 col-12 mt-5 "
                style={{ height: "42vh" }}
              >
                <div
                  className="d-flex flex-row  align-items-center justify-content-center  col-12  "
                  style={{ minHeight: "20vh" }}
                >
                  <img
                    src={item.image_path}
                    style={{
                      maxWidth: "100%",
                      maxHeight: "30vh",
                      borderRadius: "20px",
                      objectFit: "fill",
                      aspectRatio: "16 / 9",
                    }}
                  />
                </div>
                <div className="d-flex flex-row  align-items-start justify-content-start  col-12 mt-2  ">
                  <div
                    className={`   col-8 d-flex   flex-row align-items-start justify-content-start  `}
                  >
                    <span
                      className="sub-title text-start fw-bold"
                      style={{ color: `${props.menu.text_color}` }}
                    >
                      {item.title}
                    </span>
                  </div>
                  <div
                    className={`   col-4   d-flex flex-row align-items-start justify-content-end  `}
                  >
                    <span
                      className="sub-title text-end"
                      style={{ color: `${props.menu.text_color}` }}
                    >
                      € {item.price}
                    </span>
                  </div>
                </div>
                <div
                  className={`   col-10 d-flex  flex-row align-items-start justify-content-start  `}
                >
                  <span
                    className="text-16 text-start  mt-2"
                    style={{ color: `${props.menu.text_color}` }}
                  >
                    {item.description}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div
          className=" p-4 mt-4 d-flex flex-row align-items-center justify-content-start text-16"
          style={{ color: `${props.menu.title_color}` }}
        >
          <i>{props.menu.notes}</i>
        </div>
      </div>
    </>
  );
};

export default ConImmagini;
