import { createSelector } from "reselect";
import { IMenuStore } from "./MenuStore";

const getMenuState = (state: IMenuStore) => state;

export const getMenus = createSelector(getMenuState, (state) => state.menus);
export const getClientName = createSelector(
  getMenuState,
  (state) => state.clientName
);
export const getHomePage = createSelector(
  getMenuState,
  (state) => state.homePage
);
export const getAllDishes = createSelector(
  getMenuState,
  (state) => state.allDishes
);
export const getcurrentCategory = createSelector(
  getMenuState,
  (state) => state.currentCategory
);
export const getCategoryDishes = createSelector(
  getMenuState,
  (state) => state.categoryDishes
);
export const getCurrentMenu = createSelector(
  getMenuState,
  (state) => state.currentMenu
);
export const getCurrentDish = createSelector(
  getMenuState,
  (state) => state.currentDish
);
