const Home: React.FC = () => {
  return (
    <>
      <div className="bg-blue overflow-hidden">
        <div
          className={`d-flex  flex-column align-items-start justify-content-start col-12  `}
          style={{ height: "12vh" }}
        >
          <div
            className={`d-flex flex-row align-items-center justify-content-start ps-5 mt-5 `}
          >
            <span className="color-light fw-normal sub-title">
              Menu alla carta
            </span>
          </div>
        </div>
        <div
          className={`d-flex flex-row bg-light-gray-2 px-4 row flex-nowrap border align-items-center justify-content-between  `}
          style={{
            height: "8vh",
            overflowX: "scroll",
            width: `${window.screen.width}`,
          }}
        >
          <div className="btn-dark-blue  rounded  mx-3"> Antipasti </div>

          <div className="btn-dark-blue rounded mx-3"> Primi </div>

          <div className="btn-dark-blue rounded mx-3 "> Secondi </div>
          <div className="btn-dark-blue rounded mx-3 "> Contorni </div>
          <div className="btn-dark-blue rounded mx-3"> Dolci </div>
          <div className="btn-dark-blue rounded mx-3"> Drinks </div>
        </div>
      </div>
    </>
  );
};

export default Home;
