import React from "react";
import { BrowserRouter, BrowserRouter as Router } from "react-router-dom";
import Container from "./Container";

import "./App.css";
import ScrollToTop from "./ScrollToTop";
const App: React.FC = () => {
  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <Container />
      </BrowserRouter>
    </>
  );
};

export default App;
