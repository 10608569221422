import { Route, Routes } from "react-router-dom";
import Home from "./Routes/Home";

import HomePage from "./Components/HomePage";
import MenuSelector from "./Routes/MenuSelector";
import MenuView from "./Routes/MenuView";

const Container = () => {
  return (
    <Routes>
      <Route path={"/"} element={<Home />} />
      <Route path={"/menuSelector/:id"} element={<MenuSelector />} />
      <Route path={"/homepage/:id"} element={<HomePage />} />
      <Route path={"/menu/:id"} element={<MenuView />} />
      {/* <Route path={"/prezzofisso"} element={<PrezzoFisso />} /> */}
      {/* <Route path={"/allacarta"} element={<AllaCarta />} /> */}
      {/* <Route path={"/delgiorno"} element={<DelGiorno />} /> */}
    </Routes>
  );
};

export default Container;
