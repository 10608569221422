import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useStoreState, useStores } from "pullstate";
import { useNavigate } from "react-router-dom";
import MenuStore from "../store/MenuStore";
import { getHomePage } from "../store/Selector";
import { useEffect } from "react";

interface DelGiornoProps {
  menu: Menu;
}

const DelGiorno: React.FC<DelGiornoProps> = (props) => {
  const redirect = useNavigate();
  const homePage = useStoreState(MenuStore, getHomePage);

  useEffect(() => {});

  return (
    <>
      <div style={{ backgroundColor: `${props.menu.head_color}` }}>
        <div
          className={`d-flex  flex-column align-items-center justify-content-center col-12  px-4`}
          style={{ height: "17.5vh", width: "100%" }}
        >
          <div
            className=" d-flex flex-row align-items-center justify-content-center col-12  "
            style={{ height: "10vh" }}
          >
            <div className="col-1 d-flex flex-row align-items-center justify-content-center  ">
              <FontAwesomeIcon
                icon={faAngleLeft as IconProp}
                style={{
                  color: `${props.menu.title_color}`,
                  fontSize: "2.3rem",
                }}
                onClick={() =>
                  redirect(`/menuSelector/${props.menu.client_id}`)
                }
              />
            </div>
            <div
              className={`col-10   d-flex flex-row align-items-center   justify-content-center`}
            >
              <span
                className={`small-title text-center color-light `}
                style={{
                  textShadow: "2px 2px 4px black",
                  color: `${props.menu.title_color}`,
                }}
              >
                {props.menu.title}
              </span>
            </div>
            <div
              className={`col-1   d-flex flex-row align-items-center   justify-content-center`}
            ></div>
          </div>
          <div
            className={`col-10  px-5 d-flex flex-row align-items-center   justify-content-center`}
          >
            <span
              className={`text-helvetica fw-bold text-center color-light `}
              style={{
                textShadow: "2px 2px 4px black",
                color: `${props.menu.title_color}`,
              }}
            >
              {props.menu.description}
            </span>
          </div>
        </div>
        <div
          className="d-flex  flex-column align-items-center justify-content-start col-12 "
          style={{
            borderTopLeftRadius: "35px",
            borderTopRightRadius: "35px",
            backgroundColor: `${props.menu.background_color}`,
          }}
        >
          {props.menu.categories.map((cat) => (
            <>
              <div className=" col-11  d-flex flex-row align-items-center justify-content-start mt-7 mb-5">
                <span
                  className="sub-title-ml "
                  style={{ color: `${props.menu.category_color}` }}
                >
                  {cat.title}
                </span>
              </div>
              {cat.dishes.map((dish: Dish) => (
                <div
                  className="d-flex   flex-column align-items-start justify-content-start px-4 col-12 mb-3  "
                  style={{ color: `${props.menu.text_color}` }}
                >
                  <div
                    className="d-flex  flex-row align-items-start justify-content-start  col-12  "
                    style={{ minHeight: "3vh" }}
                  >
                    <div
                      className={`   col-9 d-flex  flex-row align-items-start justify-content-start  `}
                    >
                      <span className="sub-title text-start fw-bold ps-1">
                        {dish.title}
                      </span>
                    </div>
                    <div
                      className={`   col-3 d-flex  flex-row align-items-start justify-content-end  `}
                    >
                      <span className="sub-title text-end"> {dish.price}</span>
                    </div>
                  </div>
                  {dish.description !== "" ? (
                    <div
                      className={`   col-10 d-flex flex-row align-items-start justify-content-start  `}
                      style={{ minHeight: "4vh" }}
                    >
                      <span className="text-16 text-start mt-2 ps-1">
                        {dish.description}
                      </span>
                    </div>
                  ) : (
                    <div
                      className={`   col-10 d-flex flex-row align-items-start justify-content-start  `}
                      style={{ height: "2.5vh" }}
                    ></div>
                  )}
                </div>
              ))}
            </>
          ))}
        </div>

        <div
          className="col-12 d-flex flex-row align-items-start justify-content-start mt-5 ps-4"
          style={{ minHeight: "10vh" }}
        >
          <span className={`color-light text-16-helvetica `}>
            <i>{props.menu.notes}</i>
          </span>
        </div>
      </div>
    </>
  );
};

export default DelGiorno;
