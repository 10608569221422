import React, { useEffect, useState } from "react";

import styles from "./PopUpLanguages.module.scss";

import axiosHelper from "../../../helpers/axiosHelper";
import { Modal } from "react-bootstrap";

import { useStoreState } from "pullstate";
import { useNavigate } from "react-router-dom";
import italy from "../../../assets/Flag_of_Italy.svg.png";
import england from "../../../assets/istockphoto-486407806-612x612.jpg";
import france from "../../../assets/istockphoto-657672390-612x612.jpg";
import germany from "../../../assets/germany-flag-flag-germany-vector-illustration_685751-83.jpg.avif";
import russia from "../../../assets/istockphoto-691333844-612x612.jpg";
import portogallo from "../../../assets/istockphoto-1220855804-612x612.jpg";
import belgio from "../../../assets/istockphoto-483320865-612x612.jpg";

interface PopUpImageProps {
  popup: boolean;
  close: (laguage: number) => void;
  languages: Language[];
}

const languages: Language[] = [
  { idLanguage: 1, image: italy, name: "Italiano" },
  { idLanguage: 2, image: england, name: "English" },
  { idLanguage: 3, image: france, name: "Francaise" },
  { idLanguage: 4, image: germany, name: "German" },
  { idLanguage: 5, image: russia, name: "Russian" },
  { idLanguage: 6, image: portogallo, name: "Portuguese" },
  { idLanguage: 7, image: belgio, name: "Belgian" },
];

const PopUpLanguages: React.FC<PopUpImageProps> = (props) => {
  const [popup, setPopup] = useState(props.popup);

  return (
    <>
      <Modal
        show={props.popup}
        onHide={() => (setPopup(!popup), props.close && props.close(1))}
        className={`d-flex flex-column justify-content-center `}
        contentClassName={` ${styles.rounded}`}
      >
        <div
          className={`d-flex flex-column justify-content-center text-center  full bg-light-gray ${styles.rounded}`}
        >
          <Modal.Body
            className="d-flex flex-column align-items-center justify-content-start bg-light "
            style={{ minHeight: "15vh", minWidth: "50vh", borderRadius: "10px" }}
          >
            <div className="col-12 mb-3 d-flex flex-column align-content-center justify-content-start ">
              <div className="d-flex flex-row align-items-center justify-content-center">
                <span className="sub-title"> Seleziona lingua: </span>
              </div>
              <div className="d-flex flex-row flex-wrap mt-5 align-items-center justify-content-center">
                {languages.map(
                  (item) =>
                    props.languages.find(
                      (langProp) => item.idLanguage === langProp.idLanguage
                    ) && (
                      <div
                        className="d-flex flex-row mt-2 col-5  align-items-center justify-content-center cursor-pointer"
                        onClick={() => props.close(item.idLanguage)}
                      >
                        <img
                          src={item.image}
                          style={{
                            height: "50px",
                            width: "70px",
                            borderRadius: "10px",
                            border: "2px solid black",
                            objectFit: "fill",
                          }}
                        />
                      </div>
                    )
                )}
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};

export default PopUpLanguages;
