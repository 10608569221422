import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useStoreState } from "pullstate";
import MenuStore, {
  setCurrentMenu,
  setHomePage,
  setMenus,
} from "../store/MenuStore";
import { getHomePage, getMenus } from "../store/Selector";
import MenuThumb from "../Components/MenuThumb";
import axiosHelper from "../helpers/axiosHelper";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";

const MenuSelector: React.FC = () => {
  const homePage = useStoreState(MenuStore, getHomePage);
  const allMenu = useStoreState(MenuStore, getMenus);
  const redirect = useNavigate();
  const params = useParams();

  const goToMenu = async (idMenu: number) => {
    window.scrollTo(0, 0);
    await axiosHelper
      .getMenuById(idMenu)
      .then((response) => {
        console.log(response);
        setCurrentMenu(response);

        redirect(`/menu/${response.id}`);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getHomePageByClientId();

    // if (!allMenu) {
    getAllClientMenu();
    // }
  }, []);

  const getHomePageByClientId = async () => {
    if (params.id) {
      await axiosHelper
        .getHomePageByIdClient(parseInt(params.id))
        .then((response) => {
          setHomePage(response);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const getAllClientMenu = async () => {
    if (params.id) {
      await axiosHelper
        .getMenusPageByIdClient(parseInt(params.id))
        .then((response) => {
          setMenus(response);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <>
      <div
        className="overflow-hidden"
        style={{ backgroundColor: `${homePage.background_color}` }}
      >
        <div
          className={`d-flex  flex-column align-items-center justify-content-center col-12 px-4  `}
          style={{ height: "15vh", width: "100%" }}
        >
          <div
            className=" d-flex flex-row align-items-center justify-content-center col-12  "
            style={{ height: "10vh" }}
          >
            <div className="col-1 d-flex flex-row align-items-center justify-content-center  ">
              <FontAwesomeIcon
                icon={faAngleLeft as IconProp}
                style={{ color: `${homePage.text_color}`, fontSize: "2.3rem" }}
                onClick={() => redirect(`/homepage/${homePage.client_id}`)}
              />
            </div>
            <div className="col-10 d-flex flex-row align-items-center justify-content-center">
              <span className="  color-light  fw-normal sub-title-ml ">
                Tutti i Menu
              </span>
            </div>
            <div className="col-1 "></div>
          </div>
        </div>
        <div
          className="col-12  d-flex flex-row  align-items-center px-4 justify-content-center  flex-wrap pb-7 "
          style={{ minHeight: "80vh" }}
        >
          {allMenu.map((item, index) => (
            <MenuThumb
              menu={item}
              key={index}
              click={(menu) => goToMenu(menu)}
            />
          ))}
        </div>
      </div>
    </>
  );
};
export default MenuSelector;
