import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faAngleLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useStoreState } from "pullstate";
import MenuStore from "../store/MenuStore";
import { getHomePage, getMenus } from "../store/Selector";

interface MenuThumbProps {
  menu: Menu;
  click: (menuId: number) => void;
}

const MenuThumb: React.FC<MenuThumbProps> = (props) => {
  const homePage = useStoreState(MenuStore, getHomePage);


  return (
    <>
      <div
        className="col-5 pb-4  d-flex flex-column align-items-center justify-content-between mt-10 px-4 me-5"
        style={{
          minHeight: "160px",
          borderRadius: "20px",
          border: "1px solid black",
          backgroundColor: `${props.menu.thumb_background_color}`,
        }}
      >
        <div
          className="d-flex flex-row align-items-center justify-content-center border"
          style={{
            height: "90px",
            width: "90px",
            borderRadius: "40%",
            transform: "translateY(-45px)",
          }}
        >
          <img
            src={props.menu.image_path}
            style={{
              width: "90px",
              height: "90px",
              borderRadius: "40%",
              objectFit: "fill",
            }}
          />
        </div>
        <div
          className="col-12 mb-5 d-flex flex-row align-items-center justify-content-start "
          style={{ color: `${props.menu.thumb_text_color}`, height:'70px' }}
        >
          <span className="text-16 fw-bold">
            {props.menu.title}
            {props.menu.id}
          </span>
        </div>
        <div
          className={`d-flex  flex-row align-items-center justify-content-center btn-${homePage.button_color}`}
          style={{
            height: "60px",
            width: "60px",
            borderRadius: "40%",
            transform: "translateY(30px)",
          }}
          onClick={() => props.click(props.menu.id)}
        >
          <FontAwesomeIcon
            icon={faArrowRight as IconProp}
            style={{ color: `${homePage.text_color}`, fontSize: "2rem" }}
          />
        </div>
      </div>
    </>
  );
};
export default MenuThumb;
