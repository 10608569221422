import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useNavigate } from "react-router-dom";

interface PrezzoFissoProps {
  menu: Menu;
}

const PrezzoFisso: React.FC<PrezzoFissoProps> = (props) => {
  const redirect = useNavigate();
  return (
    <>
      <div className="" style={{ backgroundColor: `${props.menu.head_color}` }}>
        <div
          className={`d-flex  flex-column align-items-center justify-content-center  col-12 px-4 `}
          // style={{ height: "20vh" }}
        >
          <div className="d-flex flex-row align-items-center justify-content-center mt-7  col-12">
            <div className="col-1  d-flex flex-row-align-items-center justify-content-center">
              <div className="col-1 d-flex flex-row align-items-center justify-content-center  ">
                <FontAwesomeIcon
                  icon={faAngleLeft as IconProp}
                  style={{
                    color: `${props.menu.title_color}`,
                    fontSize: "2.5rem",
                  }}
                  onClick={() =>
                    redirect(`/menuSelector/${props.menu.client_id}`)
                  }
                />
              </div>
            </div>
            <div
              className={`col-10  d-flex flex-row align-items-center   justify-content-center `}
            >
              <span
                className={`small-title text-center `}
                style={{
                  // textShadow: "2px 2px 4px black",
                  color: `${props.menu.title_color}`,
                }}
              >
                {props.menu.title}
              </span>
            </div>
            <div className="col-1"></div>
          </div>
          <div
            className={`col-10  px-5 d-flex flex-row align-items-center mt-3  justify-content-center`}
          >
            <span
              className={`text-helvetica fw-bold text-center `}
              style={{
                // textShadow: "2px 2px 4px black",
                color: `${props.menu.title_color}`,
              }}
            >
              {props.menu.description}
            </span>
          </div>
          <div
            className={`col-12 px-5 d-flex flex-row mb-3 align-items-center pt-2 justify-content-center`}
          >
            <span
              className={`sub-title fw-bold text-center  `}
              style={{
                // textShadow: "2px 2px 4px black",
                color: `${props.menu.title_color}`,
              }}
            >
              € {props.menu.price}
            </span>
          </div>
        </div>
        <div
          className="d-flex  flex-column align-items-center justify-content-start col-12  pb-5"
          style={{
            borderTopLeftRadius: "35px",
            borderTopRightRadius: "35px",
            minHeight: "70vh",
            backgroundColor: `${props.menu.background_color}`,
          }}
        >
          {props.menu.categories.map((cat) => (
            <>
              <div className=" col-10  d-flex flex-row align-items-center justify-content-center mt-7 mb-5">
                <span
                  className="sub-title text-center "
                  style={{ color: `${props.menu.category_color}` }}
                >
                  {cat.title}
                </span>
              </div>
              {cat.dishes.map((dish) => (
                <>
                  <div className="d-flex flex-row align-items-start  justify-content-center col-10  ">
                    <span
                      className="sub-title-helvetica fw-bold text-center"
                      style={{
                        color: `${props.menu.text_color}`,
                      }}
                    >
                      {dish.title}
                    </span>
                  </div>
                  {dish.description !== "" ? (
                    <div
                      className="d-flex  mb-3 flex-row align-items-start justify-content-center col-10  "
                      style={{
                        color: `${props.menu.text_color}`,
                        minHeight: "3vh",
                      }}
                    >
                      <span className="text-16-helvetica text-center ">
                        {dish.description}
                      </span>
                    </div>
                  ) : (
                    <div
                      className="d-flex flex-row align-items-center justify-content-center col-10  "
                      style={{
                        color: `${props.menu.text_color}`,
                        minHeight: "2vh",
                      }}
                    ></div>
                  )}
                </>
              ))}
            </>
          ))}
        </div>

        <div
          className="col-12 d-flex flex-row align-items-start justify-content-start mt-3 ps-4"
          style={{ minHeight: "5vh" }}
        >
          <span
            className={` text-16-helvetica `}
            style={{ color: `${props.menu.title_color}` }}
          >
            <i>{props.menu.notes}</i>
          </span>
        </div>
      </div>
    </>
  );
};

export default PrezzoFisso;
