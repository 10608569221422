import { Store } from "pullstate";

export interface IMenuStore {
  menus: Menu[];
  currentMenu: Menu;
  currentCategory: Category;
  currentDish: Dish;
  categoryDishes: Dish[];
  clientName: string;
  homePage: HomePage;
  allDishes: Dish[];
}
const MenuStore = new Store<IMenuStore>({
  menus: [],
  currentMenu: {
    id: 0,
    pos: 0,
    title: "",
    description: "",
    notes: "",
    online: false,
    icon: "",
    categories: [],
    price: "",
    typology_id: 0,
    show_prices: false,
    image_path: "",
    thumb_text_color: "",
    thumb_background_color: "",
    font_title: "",
    font_text: "",
    text_color: "",
    background_color: "",
    category_color: "",
    client_id: 0,
    head_color: "",
    buttons_color: "",
    title_color: "",
  },
  currentCategory: {
    id: 0,
    title: "",
    pos: 0,
    description: "",
    notes: "",
    dishes: [],
    hide_allergens: false,
    menu_id: 0,
  },
  categoryDishes: [],
  currentDish: {
    id: 0,
    title: "",
    pos: 0,
    description: "",
    price: 0,
    online: false,
    image_path: "",
    category_id: 0,
  },
  clientName: "",
  homePage: {
    id: 0,
    title: "",
    languages: [],
    subtitle: "",
    text_color: "",
    button_text: "",
    button_color: "",
    logo_path: "",
    image_path: "",
    background_color: "",
    client_id: 0
  },
  allDishes: [],
});

export default MenuStore;

export const setMenus = (menus: Menu[]) => {
  MenuStore.update((s) => {
    s.menus = menus;
  });
};

export const setCurrentMenu = (menu: Menu) => {
  MenuStore.update((s) => {
    s.currentMenu = menu;
  });
};

export const AddMenuCategory = (category: Category) => {
  MenuStore.update((s) => {
    s.currentMenu.categories?.push(category);
  });
};

export const setCurrentCategory = (category: Category) => {
  MenuStore.update((s) => {
    s.currentCategory = category;
  });
};

export const setCategoryDishes = (dishes: Dish[]) => {
  MenuStore.update((s) => {
    s.categoryDishes = dishes;
  });
};

export const setCurrentDish = (dish: Dish) => {
  MenuStore.update((s) => {
    s.currentDish = dish;
  });
};

export const setClientName = (name: string) => {
  MenuStore.update((s) => {
    s.clientName = name;
  });
};

export const setHomePage = (homePage: HomePage) => {
  MenuStore.update((s) => {
    s.homePage = homePage;
  });
};

export const setAllDishes = (dishes: Dish[]) => {
  MenuStore.update((s) => {
    s.allDishes = dishes;
  });
};
