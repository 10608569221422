import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useStoreState } from "pullstate";
import MenuStore, { setCurrentMenu, setHomePage } from "../store/MenuStore";
import { getCurrentMenu, getHomePage, getMenus } from "../store/Selector";
import MenuThumb from "../Components/MenuThumb";
import AllaCarta from "../Components/AllaCarta";

import DelGiorno from "../Components/DelGiorno";
import PrezzoFisso from "../Components/PrezzoFisso";
import { useEffect } from "react";
import axiosHelper from "../helpers/axiosHelper";

import ConImmagini from "../Components/ConImmagini";
import { useParams } from "react-router-dom";

const MenuView: React.FC = () => {
  const homePage = useStoreState(MenuStore, getHomePage);
  const allMenu = useStoreState(MenuStore, getMenus);
  const currentMenu = useStoreState(MenuStore, getCurrentMenu);
  const params = useParams();

  useEffect(() => {
    getMenuById();
  }, []);

  const fetchHomePage = async () => {
    if (currentMenu) {
      await axiosHelper
        .getHomePageByIdClient(currentMenu.client_id)
        .then((response) => {
          setHomePage(response);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const getMenuById = async () => {
    if (params.id) {
      console.log(params.id);
      await axiosHelper
        .getMenuById(parseInt(params.id))
        .then((response: any) => {
          console.log(response);
          setCurrentMenu(response);
          localStorage.setItem("ciccio", response);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <>
      {currentMenu.typology_id === 1 && <AllaCarta menu={currentMenu} />}
      {currentMenu.typology_id === 2 && <DelGiorno menu={currentMenu} />}
      {currentMenu.typology_id === 3 && <PrezzoFisso menu={currentMenu} />}
      {currentMenu.typology_id === 4 && <ConImmagini menu={currentMenu} />}
    </>
  );
};
export default MenuView;
