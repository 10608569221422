import axios from "axios";

class axiosHelper {
  getHomePageByIdClient(idClient: number) {
    return axios
      .get<HomePage>(
        `${process.env.REACT_APP_API_ENDPOINT}/DLCMenu/getHomePage/${idClient}`
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }
  getMenusPageByIdClient(idClient: number) {
    return axios
      .get<Menu[]>(
        `${process.env.REACT_APP_API_ENDPOINT}/DLCMenu/allMenu/${idClient}`
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  getMenuById(idMenu: number) {
    return axios
      .get<Menu>(`${process.env.REACT_APP_API_ENDPOINT}/DLCMenu/menu/${idMenu}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }
}

export default new axiosHelper();
